import React, { useContext } from 'react';
import {
  VictoryChart,
  VictoryGroup,
  VictoryBar,
  VictoryTheme,
  VictoryAxis,
} from 'victory';
import { StateContext } from './State';

export default function Bar({ data }: any) {
  const [global] = useContext(StateContext) as any;
  // const cl = useStyles(global.cyber, global.colors);
  const { cyber, themes } = global;
  const skin = themes[cyber ? 1 : 0];

  return (
    <>
      <VictoryChart
        width={400}
        height={300}
        theme={VictoryTheme.material}
        domainPadding={{ x: [40, 0] }}
      >
        <VictoryAxis
          dependentAxis
          tickFormat={t => `$${Math.round(t) / 1000}k`}
          label={'Cost'}
          style={{
            axis: {},
            ticks: { padding: -5 },
            tickLabels: { fill: skin.primary, fontSize: 12 },
            axisLabel: { padding: 38, fill: skin.secondary },
          }}
          animate={{
            duration: 700,
            easing: 'polyIn',
          }}
        />
        <VictoryAxis
          tickFormat={t => `${Math.round(t - 1)}`}
          tickCount={8}
          label={'Ownership (years)'}
          style={{
            axis: {},
            ticks: { padding: -5 },
            tickLabels: { fill: skin.primary, fontSize: 12 },
            axisLabel: { padding: 25, fill: skin.secondary },
          }}
          animate={{
            duration: 700,
            easing: 'polyIn',
          }}
        />
        <VictoryGroup
          style={{
            data: { strokeWidth: 1, fillOpacity: 0.4 },
          }}
          offset={30}
        >
          <VictoryBar
            alignment="start"
            barWidth={15}
            style={{
              data: { fill: skin.secondary, stroke: skin.secondary },
              labels: {
                fontSize: 7,
                textAnchor: 'start',
                padding: 9,
                fill: skin.secondary,
              },
            }}
            labels={({ datum }) => `$${Math.round(datum.y / 1000)}k`}
            data={data[0].map((x: any, i: number) => ({
              x: i + 1,
              y: x,
            }))}
            animate={{
              duration: 700,
              easing: 'polyIn',
            }}
          />

          <VictoryBar
            alignment="end"
            barWidth={15}
            style={{
              data: { stroke: skin.primary, fill: skin.primary },
              labels: {
                fontSize: 7,
                textAnchor: 'end',
                padding: 9,
                fill: skin.primary,
              },
            }}
            labels={({ datum }) => `$${Math.round(datum.y / 1000)}k`}
            data={data[1].map((x: any, i: number) => ({
              x: i + 1,
              y: x,
            }))}
            animate={{
              duration: 700,
              easing: 'polyInOut',
            }}
          />
        </VictoryGroup>
      </VictoryChart>
    </>
  );
}
