import React, { useContext } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { InputAdornment, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { StateContext, ACTION } from './State';
import NumberFormatCustom from './NumberFormatCustom';

const useStyles = (cyber: boolean, colors: any) =>
  makeStyles((theme: Theme) =>
    createStyles({
      grid: {
        borderRight: 'solid 1px #ccc',
        borderWidth: 1,
      },
      root: {
        padding: 20,
        color: cyber ? colors.primary : colors.text,
      },
      input: {
        marginRight: 15,
        marginBottom: 15,
        width: '100%',
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: cyber ? '#2c85a8' : colors.black,
          },
          // '&:hover fieldset': {
          //   borderColor: 'yellow',
          // },
          // '&.Mui-focused fieldset': {
          //   borderColor: 'green',
          // },
        },
      },
      formControl: {
        width: '100%',
        paddingBottom: 15,
        '& .MuiSelect-icon': {
          fill: cyber ? '#00b7ff' : colors.black,
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: cyber ? '#2c85a8' : colors.black,
          },
          // '&:hover fieldset': {
          //   borderColor: 'yellow',
          // },
          // '&.Mui-focused fieldset': {
          //   borderColor: 'green',
          // },
        },
      },
    })
  )();

export default function InputComp() {
  const [global, dispatch] = useContext(StateContext) as any;

  const { cyber, themes } = global;
  const skin = themes[cyber ? 1 : 0];
  const cl = useStyles(global.cyber, skin);

  const update = (type: any) => (e: any) => {
    dispatch({ type, payload: e.target.value });
  };

  return (
    <div className={cl.root}>
      {/* <Paper className={cl.root}> */}
      <Typography variant="h5">Competition</Typography>
      <br></br>
      <TextField
        label="MSRP"
        className={cl.input}
        value={global.comp_msrp}
        onChange={update(ACTION.COMP_MSRP)}
        InputProps={{
          inputComponent: NumberFormatCustom,
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        variant="outlined"
      />
      <TextField
        label="Avg. Yearly Maintanance"
        className={cl.input}
        value={global.comp_maint}
        onChange={update(ACTION.COMP_MAINT)}
        InputProps={{
          inputComponent: NumberFormatCustom,
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        variant="outlined"
      />
      <TextField
        label="MPG"
        className={cl.input}
        value={global.comp_efficiency}
        onChange={update(ACTION.COMP_MPG)}
        InputProps={{
          inputComponent: NumberFormatCustom,
          endAdornment: <InputAdornment position="start">mi</InputAdornment>,
        }}
        variant="outlined"
      />
      <TextField
        label="Price per Gallon"
        className={cl.input}
        value={global.comp_fuel_cost}
        onChange={update(ACTION.COMP_FUEL)}
        InputProps={{
          inputComponent: NumberFormatCustom,
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        variant="outlined"
      />
      {/* </Paper> */}
    </div>
  );
}
