import React, { useContext } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { StateContext } from './State';

const useStyles = (cyber: boolean, skin: any) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        padding: 8,
      },
      focus: {
        fontWeight: 'bolder',
        color: skin.primary,
        fontSize: '20pt',
      },
    })
  )();

export default function Summary({ data }: any) {
  const [global] = useContext(StateContext) as any;
  const { cyber, themes } = global;
  const cl = useStyles(cyber, themes[cyber ? 1 : 0]);

  const breakEven = () => {
    const savingStart =
      (global.cyber_msrp - global.comp_msrp) /
      ((global.avg_miles / global.comp_efficiency) * global.comp_fuel_cost +
        global.comp_maint -
        ((global.avg_miles / global.cyber_efficiency) * global.cyber_fuel_cost +
          global.cyber_maint));
    return Math.round(savingStart * 100) / 100;
  };

  const rate = () =>
    Math.round(
      Math.abs(data[0][0] - data[0][1] - (data[1][0] - data[1][1])) * 100
    ) / 100;

  const emissions = () =>
    Math.round((20 * (global.avg_miles / global.comp_efficiency)) / 20) / 100;

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Typography className={cl.root} variant="h5">
          You will start seeing savings after{' '}
          <span className={cl.focus}>{breakEven()} years</span>, at a rate of{' '}
          <span className={cl.focus}>${rate()} per year</span>.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography className={cl.root} variant="h5">
          You avoid emmiting{' '}
          <span className={cl.focus}>
            {emissions()} tons of CO<sub>2</sub> each year
          </span>
          .
        </Typography>
      </Grid>
    </Grid>
  );
}
