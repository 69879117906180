import React, { useContext } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  IconButton,
  FormControlLabel,
  Grid,
} from '@material-ui/core';
import { Switch as UISwitch } from '@material-ui/core';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.css';
import CyberCalc from './CyberCalc';
import { StateContext } from './State';

const useStyles: any = (cyber: boolean, pal: any) =>
  makeStyles((theme: Theme) =>
    createStyles({
      background: {
        backgroundColor: pal.background,
      },
      root: {
        flexGrow: 1,
        background: cyber
          ? `linear-gradient(45deg, ${pal.primary}, ${pal.secondary})`
          : pal.text,
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      title: {
        flexGrow: 1,
        color: cyber ? pal.text : 'white',
      },
      cyberLabel: cyber
        ? {
            '& .MuiFormControlLabel-label': {
              fontSize: 22,
            },
            color: pal.text,
          }
        : {
            fontSize: 20,
            background: `linear-gradient(45deg,${pal.cyber_primary}, ${pal.cyber_secondary})`,
            WebkitBackgroundClip: 'text',
            // -webkit-background-clip: text;
            '-webkit-text-fill-color': 'transparent',
            '& .MuiFormControlLabel-label': {
              fontSize: 22,
            },
          },
    })
  )();

export default function App() {
  const [state, dispatch] = useContext(StateContext) as any;
  const { cyber, themes } = state;
  const classes = useStyles(cyber, themes[cyber ? 1 : 0]);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Container className="landing" fixed style={{ height: '100%' }}>
            <Grid
              style={{ height: '100%' }}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Button variant="contained" href="/cybertruck">
                CyberTruck Calculator
              </Button>
            </Grid>
          </Container>
        </Route>
        <Route path="/cybertruck">
          <div className={classes.background}>
            <AppBar position="static" className={classes.root}>
              <Toolbar>
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                ></IconButton>
                <Typography variant="h6" className={classes.title}></Typography>
                <FormControlLabel
                  className={classes.cyberLabel}
                  control={
                    <UISwitch
                      checked={state.checkedA}
                      onChange={() => dispatch({ type: 'TOGGLE' })}
                      value="cyber"
                    />
                  }
                  label={cyber ? 'Revert' : 'Cyber Mode'}
                  labelPlacement="start"
                />
              </Toolbar>
            </AppBar>
            <Container fixed>
              <CyberCalc />
            </Container>
          </div>
        </Route>
      </Switch>
    </Router>
  );
}
