import React, { useReducer } from 'react';

export const StateContext = React.createContext({});

export enum ACTION {
  MILES,
  COMP_MSRP,
  COMP_MAINT,
  COMP_MPG,
  COMP_FUEL,
  CYBER_OPTION,
  CYBER_MSRP,
  CYBER_MAINT,
  CYBER_EFF,
  CYBER_FUEL,
}

const initState: any = {
  cyber: false,
  avg_miles: 10000,

  comp_msrp: 35000,
  comp_fuel_cost: 3.75,
  comp_efficiency: 15,
  comp_maint: 500,

  cyber_option: 0,
  cyber_msrp: 40000,
  cyber_fuel_cost: 0.21,
  cyber_efficiency: 3,
  cyber_maint: 200,
  themes: [
    {
      background: 'white',
      primary: '#00bcd4',
      secondary: '#9e9e9e',
      third: '#03a9f4',
      text: '#484848',
      cyber_primary: 'rgb(255, 0, 255)',
      cyber_secondary: 'rgb(0, 167, 239)',
    },
    {
      background: '#484848',
      primary: 'rgb(255, 0, 255)',
      secondary: 'rgb(0, 167, 239)',
      third: 'blue',
      text: '#484848',
    },
  ],
};

const reducer = (state: any, action: any) => {
  let invalid = false;
  switch (action.type) {
    case 'TOGGLE': {
      return { ...state, cyber: !state.cyber };
    }
    case ACTION.MILES: {
      let val = parseInt(action.payload);
      if (isNaN(val)) val = 0;
      return { ...state, avg_miles: val };
    }
    case ACTION.COMP_MSRP: {
      let val = parseInt(action.payload);
      if (isNaN(val)) val = 0;
      return { ...state, comp_msrp: val };
    }
    case ACTION.COMP_MAINT: {
      let val = parseInt(action.payload);
      if (isNaN(val)) val = 0;
      return { ...state, comp_maint: val };
    }
    case ACTION.COMP_MPG: {
      const val = parseInt(action.payload);
      invalid = isNaN(val) || action.payload === '';
      if (invalid) return { ...state, comp_efficiency: action.payload };
      return { ...state, comp_efficiency: val };
    }
    case ACTION.COMP_FUEL: {
      const input = action.payload;
      const val = parseFloat(input);
      invalid =
        isNaN(val) || input === '' || input.indexOf('.') === input.length - 1;
      console.log('isInvalid', invalid);
      if (invalid) return { ...state, comp_fuel_cost: input };
      return { ...state, comp_fuel_cost: val };
    }
    case ACTION.CYBER_MSRP: {
      let val = parseInt(action.payload);
      if (isNaN(val)) val = 0;
      return { ...state, cyber_msrp: val };
    }
    case ACTION.CYBER_MAINT: {
      let val = parseInt(action.payload);
      if (isNaN(val)) val = 0;
      return { ...state, cyber_maint: val };
    }
    case ACTION.CYBER_EFF: {
      const val = parseInt(action.payload);
      invalid = isNaN(val) || action.payload === '';
      if (invalid) return { ...state, cyber_efficiency: action.payload };
      return { ...state, cyber_efficiency: val };
    }
    case ACTION.CYBER_FUEL: {
      const input = action.payload;
      const val = parseFloat(input);
      invalid =
        isNaN(val) || input === '' || input.indexOf('.') === input.length - 1;
      if (invalid) return { ...state, cyber_fuel_cost: input };
      return { ...state, cyber_fuel_cost: val };
    }
    case ACTION.CYBER_OPTION: {
      const cyber_option = action.payload;
      let cyber_msrp = 39900;
      if (cyber_option == 1) cyber_msrp = 49900;
      if (cyber_option == 2) cyber_msrp = 69900;

      return { ...state, cyber_option, cyber_msrp };
    }
    default:
      console.log('ACTION NOT IMPLEMENTED: ', action);
      return state;
  }
};

const StateProvider = (props: any) => {
  return (
    <StateContext.Provider value={useReducer(reducer, initState)}>
      {props.children}
    </StateContext.Provider>
  );
};

export { StateProvider };
