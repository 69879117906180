import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { purple, cyan } from '@material-ui/core/colors';
import { StateProvider } from './State';
import { StateContext } from './State';

const theme = (cyber: any) =>
  cyber
    ? createMuiTheme({
        palette: {
          type: 'dark',
          primary: purple,
          secondary: purple,
          text: {
            primary: '#00b7ff',
            secondary: '#00b7ff',
          },
        },
      })
    : createMuiTheme({
        palette: {
          type: 'light',
          primary: cyan,
        },
      });

ReactDOM.render(
  <StateProvider>
    <Wrap />
  </StateProvider>,
  document.getElementById('root')
);

function Wrap() {
  const [state] = useContext(StateContext) as any;

  return (
    <ThemeProvider theme={theme(state.cyber)}>
      <App />
    </ThemeProvider>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
