import React, { useContext } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import {
  InputAdornment,
  Typography,
  InputLabel,
  Select,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { StateContext, ACTION } from './State';
import NumberFormatCustom from './NumberFormatCustom';

const useStyles = (cyber: boolean, colors: any) =>
  makeStyles((theme: Theme) =>
    createStyles({
      grid: {
        borderRight: 'solid 1px #ccc',
        borderWidth: 1,
      },
      root: {
        padding: 20,
        color: cyber ? colors.primary : colors.text,
      },
      input: {
        marginRight: 15,
        marginBottom: 15,
        width: '100%',
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: cyber ? '#2c85a8' : colors.black,
          },
          // '&:hover fieldset': {
          //   borderColor: 'yellow',
          // },
          // '&.Mui-focused fieldset': {
          //   borderColor: 'green',
          // },
        },
      },
      formControl: {
        width: '100%',
        paddingBottom: 15,
        '& .MuiSelect-icon': {
          fill: cyber ? '#00b7ff' : colors.black,
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: cyber ? '#2c85a8' : colors.black,
          },
          // '&:hover fieldset': {
          //   borderColor: 'yellow',
          // },
          // '&.Mui-focused fieldset': {
          //   borderColor: 'green',
          // },
        },
      },
    })
  )();

export default function InputCyber() {
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [global, dispatch] = useContext(StateContext) as any;

  const { cyber, themes } = global;
  const skin = themes[cyber ? 1 : 0];
  const cl = useStyles(global.cyber, skin);

  let cyberOptions = [
    {
      label: 'Single Motor RWD',
      cyber_msrp: 39900,
      cyber_efficiency: 3,
    },
    {
      label: 'Dual Motor AWD',
      cyber_msrp: 49900,
      cyber_efficiency: 3,
    },
    {
      label: 'Tri Motor AWD',
      cyber_msrp: 69900,
      cyber_efficiency: 3,
    },
  ];

  const update = (type: any) => (e: any) => {
    dispatch({ type, payload: e.target.value });
  };

  return (
    <div className={cl.root}>
      {/* <Paper className={cl.root}> */}
      <Typography variant="h5">Cybertruck</Typography>
      <br></br>
      <div>
        <FormControl variant="outlined" className={cl.formControl}>
          <InputLabel ref={inputLabel} htmlFor="outlined-version-native-simple">
            Version
          </InputLabel>
          <Select
            native
            value={global.cyber_option}
            onChange={update(ACTION.CYBER_OPTION)}
            labelWidth={60}
            inputProps={{
              name: 'version',
              id: 'outlined-version-native-simple',
            }}
          >
            {cyberOptions.map((x, i) => (
              <option key={i} value={i}>
                {x.label}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>
      <TextField
        label="Avg. Yearly Maintanance"
        className={cl.input}
        value={global.cyber_maint}
        onChange={update(ACTION.CYBER_MAINT)}
        InputProps={{
          inputComponent: NumberFormatCustom,
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        variant="outlined"
      />
      <TextField
        label="Price per kWh"
        className={cl.input}
        value={global.cyber_fuel_cost}
        onChange={update(ACTION.CYBER_FUEL)}
        InputProps={{
          inputComponent: NumberFormatCustom,
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        variant="outlined"
      />
      {/* </Paper> */}
    </div>
  );
}
