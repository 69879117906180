import React, { useContext } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Divider } from '@material-ui/core';
import Bar from './Bar';
import Summary from './Summary';
import { StateContext } from './State';
import InputMiles from './InputMiles';
import InputCyber from './InputCyber';
import InputComp from './InputComp';

const useStyles = (cyber: boolean, colors: any) =>
  makeStyles((theme: Theme) =>
    createStyles({
      grid: {
        borderRight: 'solid 1px #ccc',
        borderWidth: 1,
      },
      root: {
        padding: 20,
        color: cyber ? colors.primary : colors.text,
      },
      input: {
        marginRight: 15,
        marginBottom: 15,
        width: '100%',
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: cyber ? '#2c85a8' : colors.black,
          },
          // '&:hover fieldset': {
          //   borderColor: 'yellow',
          // },
          // '&.Mui-focused fieldset': {
          //   borderColor: 'green',
          // },
        },
      },
      formControl: {
        width: '100%',
        paddingBottom: 15,
        '& .MuiSelect-icon': {
          fill: cyber ? '#00b7ff' : colors.black,
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: cyber ? '#2c85a8' : colors.black,
          },
          // '&:hover fieldset': {
          //   borderColor: 'yellow',
          // },
          // '&.Mui-focused fieldset': {
          //   borderColor: 'green',
          // },
        },
      },
    })
  )();

export default function CyberCalc() {
  const [global] = useContext(StateContext) as any;
  const { cyber, themes } = global;
  const skin = themes[cyber ? 1 : 0];
  const cl = useStyles(global.cyber, skin);

  const years = (y: number) => ({
    comp:
      y *
        ((global.avg_miles / global.comp_efficiency) * global.comp_fuel_cost +
          global.comp_maint) +
      global.comp_msrp,
    cyber:
      y *
        ((global.avg_miles / global.cyber_efficiency) * global.cyber_fuel_cost +
          global.cyber_maint) +
      global.cyber_msrp,
  });

  const data = [...Array(8).keys()].reduce(
    (acc, x) => {
      const res = years(x);
      acc[0].push(res.comp);
      acc[1].push(res.cyber);
      return acc;
    },
    [[], []] as any[]
  );

  return (
    <div className={cl.root}>
      <br></br>

      <Grid container spacing={6}>
        <Grid item xs={12} md={7}>
          <InputMiles />
        </Grid>
        <Grid item sm={6} className={cl.grid}>
          <InputCyber />
        </Grid>
        <Grid item sm={6}>
          <InputComp />
        </Grid>
      </Grid>
      <br></br>
      <br></br>
      <Divider variant="middle" />
      <br></br>
      <Summary data={data} />
      <br></br>
      <Divider variant="middle" />
      <Bar data={data} />
    </div>
  );
}
