import React, { useContext } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { InputAdornment, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { StateContext, ACTION } from './State';
import NumberFormatCustom from './NumberFormatCustom';

const useStyles = (cyber: boolean, colors: any) =>
  makeStyles((theme: Theme) =>
    createStyles({
      grid: {
        borderRight: 'solid 1px #ccc',
        borderWidth: 1,
      },
      root: {
        padding: 20,
        color: cyber ? colors.primary : colors.text,
      },
      input: {
        marginRight: 15,
        marginBottom: 15,
        width: '100%',
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: cyber ? '#2c85a8' : colors.black,
          },
          // '&:hover fieldset': {
          //   borderColor: 'yellow',
          // },
          // '&.Mui-focused fieldset': {
          //   borderColor: 'green',
          // },
        },
      },
      formControl: {
        width: '100%',
        paddingBottom: 15,
        '& .MuiSelect-icon': {
          fill: cyber ? '#00b7ff' : colors.black,
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: cyber ? '#2c85a8' : colors.black,
          },
          // '&:hover fieldset': {
          //   borderColor: 'yellow',
          // },
          // '&.Mui-focused fieldset': {
          //   borderColor: 'green',
          // },
        },
      },
    })
  )();

export default function InputMiles() {
  const [global, dispatch] = useContext(StateContext) as any;

  const { cyber, themes } = global;
  const skin = themes[cyber ? 1 : 0];
  const cl = useStyles(global.cyber, skin);

  return (
    <div className={cl.root}>
      {/* <Paper className={cl.root}> */}
      {/* <Typography variant="h5">General Information</Typography> */}
      <br></br>
      <Typography variant="h5">
        How many miles do you do drive each year?
      </Typography>
      <br />
      <TextField
        label="Miles driven per year"
        onChange={e => {
          dispatch({ type: ACTION.MILES, payload: e.target.value });
        }}
        className={cl.input}
        value={global.avg_miles}
        InputProps={{
          inputComponent: NumberFormatCustom as any,
          endAdornment: <InputAdornment position="start">mi</InputAdornment>,
        }}
        variant="outlined"
      />
      {/* <FormHelperText id="outlined-weight-helper-text">
              Miles driven per year.
            </FormHelperText> */}
      {/* </Paper> */}
    </div>
  );
}
